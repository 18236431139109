import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import { Header, HeadingH1, HeadingH4, Holder, S, Title } from 'poly-book';
import { BackArrowBase, HeaderInfoLoader } from 'poly-site-ui';
import { useLocation, useNavigate } from 'poly-client-routing';

import { routes } from '../routes.js';

export const HeaderContainer = styled(Header)`
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
`;

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

function BackArrow() {
  const location = useLocation();
  const navigate = useNavigate();
  const previousUrl = R.pathOr(
    routes.home,
    ['state', 'previous', 'pathname'],
    location,
  );
  return (
    <BackArrowBase
      onClick={() => navigate(previousUrl)}
      height={10}
      alignLeft={false}
    />
  );
}
export function DetailsHeader({ title, subtitle, label, loading }) {
  return (
    <HeaderContainer.Sub>
      <HeaderRow>
        <HeaderContainer.Block>
          <Holder margin={20}>
            <BackArrow />
            {loading ? (
              <HeaderInfoLoader />
            ) : (
              <Title>
                <Title.Block>
                  <Title.Item>
                    <HeadingH1 lighter>{title}</HeadingH1>
                  </Title.Item>
                  <Title.Item>
                    <HeadingH4 lighter>{subtitle}</HeadingH4>
                  </Title.Item>
                </Title.Block>
                <Title.Block>
                  <Title.Item>
                    <S type="title" textColor="#4676b4">
                      {label}
                    </S>
                  </Title.Item>
                </Title.Block>
              </Title>
            )}
          </Holder>
        </HeaderContainer.Block>
      </HeaderRow>
    </HeaderContainer.Sub>
  );
}

DetailsHeader.propTypes = {
  title: string,
  label: string,
  subtitle: string,
  loading: bool,
};
