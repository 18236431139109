import styled from 'styled-components';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { DateRangePiker, PrintAndExportButtons, A, ALERTS } from 'poly-site-ui';
import { Layout, Header, HeadingH2, Button, S } from 'poly-book';
import { formatDate, isNilOrEmpty, toDate } from 'poly-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import {
  generateFileNameByTitle,
  openPrintWindowWithData,
  performExcelExport,
} from 'poly-client-utils';

import { Navigation } from '../Navigation.js';
import { AssetsTypeSelect } from './AssetsTypeSelect.js';
import { PrintLayout } from '../../components/print-visible-table.js';
import { PropertySelect } from '../SpendReportPage/PropertySelect.js';
import { AssetReactiveSpendReportTable } from './AssetReactiveSpendReportTable.js';
import { getAssetReactiveSpendReportsXlsConfig } from './getAssetReactiveSpendReportsXlsConfig.js';

const HeaderTitle = styled(HeadingH2)`
  font-size: 22px;
`;

const HeaderBlock = styled(Header.Block)`
  height: 85px;
  justify-content: space-between;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 16px;
`;

function ExportButtonComponent({ exportFunction, textColor }) {
  return (
    <A textColor={textColor} onClick={exportFunction}>
      {EXPORT_XLS_CAPTION}
    </A>
  );
}

ExportButtonComponent.propTypes = {
  exportFunction: func.isRequired,
  textColor: string,
};

export function AssetReactiveSpendReportHeader({
  filter,
  setFilter,
  report,
  isTransparent,
  isReportEnabled,
}) {
  const [filterValues, setFilterValues] = useState({
    properties: [],
    assetTypes: [],
    endDate: endOfDay(new Date()),
    startDate: startOfDay(subMonths(new Date(), 1)),
    ...filter,
  });

  const onChangeProperty = (properties) => {
    setFilterValues({
      ...filterValues,
      properties,
    });
  };

  const onChangeAssetType = (assetTypes) => {
    setFilterValues({
      ...filterValues,
      assetTypes,
    });
  };

  const onChangeDateRange = ({ startDate, endDate }) =>
    setFilterValues({
      ...filterValues,
      startDate: startOfDay(toDate(startDate)),
      endDate: endOfDay(toDate(endDate)),
    });

  const handlePreview = () => setFilter(filterValues);

  const handlePrintReport =
    ({ title }) =>
    () => {
      if (isNilOrEmpty(report.reports)) {
        toast.error(ALERTS.NoDataToDisplay);
        return Promise.resolve(null);
      }

      return openPrintWindowWithData({
        report,
        isTransparent,
        fileName: generateFileNameByTitle(title),
        Table: AssetReactiveSpendReportTable,
        metaData: { title },
        Layout: PrintLayout,
        skipFirefoxStyles: true,
      });
    };

  const handleExportReport = () => {
    if (isNilOrEmpty(report.reports)) {
      toast.error(ALERTS.NoDataToDisplay);
      return;
    }

    const config = getAssetReactiveSpendReportsXlsConfig({
      isTransparent,
      startDate: formatDate(filter.startDate),
      endDate: formatDate(filter.endDate),
      report,
    });
    performExcelExport(config);
  };

  return (
    <Layout.Header>
      <Navigation />
      <Header.Sub>
        <HeaderBlock>
          <FlexWrapper>
            <HeaderTitle lighter>Asset Reactive Spend Report</HeaderTitle>
            <PropertySelect
              value={filterValues?.properties}
              onChange={onChangeProperty}
              width="250px"
            />
            <AssetsTypeSelect
              value={filterValues?.assetTypes}
              onChange={onChangeAssetType}
              width="250px"
            />
            <DateRangePiker
              isLabel={false}
              startDate={filterValues?.startDate}
              endDate={filterValues?.endDate}
              onChange={onChangeDateRange}
            />
          </FlexWrapper>
          <FlexWrapper>
            <Button
              disabled={!isReportEnabled}
              mode="orange"
              onClick={handlePreview}
            >
              <S type="title">Preview</S>
            </Button>
            <PrintAndExportButtons
              printTitle="Asset Reactive Spend Report"
              exportFunction={handleExportReport}
              printFunction={handlePrintReport}
              ExportButtonComponent={ExportButtonComponent}
              textColor="#436CBE"
            />
          </FlexWrapper>
        </HeaderBlock>
      </Header.Sub>
    </Layout.Header>
  );
}

AssetReactiveSpendReportHeader.propTypes = {
  filter: shape({
    properties: arrayOf(
      shape({
        value: string.isRequired,
        label: string.isRequired,
      }),
    ),
    assetTypes: arrayOf(
      shape({
        value: string.isRequired,
        label: string.isRequired,
      }),
    ),
  }),
  setFilter: func.isRequired,
  isTransparent: bool,
  report: shape({ reports: arrayOf(shape({})) }),
  isReportEnabled: bool,
};
