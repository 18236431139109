import React from 'react';
import { Icon, Pip } from 'poly-book';
import styled from 'styled-components';
import { bool, func, node, shape, string } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';

const lightBlueColor = '#F6F9FD';
export const textColor = '#5E6271';
const statusTextColor = '#6F7A9F';
const titleTextColor = '#5D6883';
export const midDarkColor = '#999999';

const DetailsSectionContainer = styled.section`
  display: grid;
  background: ${lightBlueColor};
  border-radius: 4px;
  grid-template-rows: 55px 1fr;
`;

const DetailsSectionHeaderContainer = styled.div`
  padding: 16px 20px;
  display: flex;
  border-bottom: 2px solid #fff;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const DetailsHeaderText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${textColor};
  align-self: start;
`;

const StatusText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-left: 5px;
  color: ${statusTextColor};
`;

const StatusContainer = styled.div`
  align-self: end;
`;

const DetailsSectionContent = styled.div`
  display: grid;
  align-content: flex-start;
  padding: 20px;
`;

const DetailsRowContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ isVertical }) =>
    !isVertical ? '150px minmax(0, 1fr)' : '1fr'};
  grid-column-gap: 12px;
  :not(:last-child) {
    margin-bottom: 20px;
  }
  background: transparent;
  align-items: flex-start;
  justify-content: start;
  cursor: ${({ isClickable }) => isClickable && 'pointer'};
  position: relative;
`;

const DetailsTitleContainer = styled.div`
  color: ${titleTextColor};
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
`;

const DetailsValueContainer = styled.div`
  color: ${({ color }) => color || textColor};
  font-size: 14px;
  display: grid;
  align-items: flex-start;
  grid-template-columns: minmax(0, 1fr);
  svg {
    margin-right: 5px;
  }
  span {
    word-break: break-all;
  }
`;

const DetailsValue = styled.span`
  white-space: pre-line;
`;
export function DetailsRow({
  icon,
  color,
  title,
  content,
  onClick,
  isVertical,
}) {
  return (
    <DetailsRowContainer isVertical={isVertical} isClickable={!!onClick}>
      <DetailsTitleContainer>{title}</DetailsTitleContainer>
      <DetailsValueContainer color={color} onClick={onClick}>
        {icon && <Icon name={icon.name} dimensions={icon.dimensions} />}
        <DetailsValue>{content || NOTHING_UI_STRING} </DetailsValue>
      </DetailsValueContainer>
    </DetailsRowContainer>
  );
}

DetailsRow.propTypes = {
  title: string.isRequired,
  content: node,
  color: string,
  icon: shape({
    name: string.isRequired,
  }),
  isVertical: bool,
  onClick: func,
};

export function DetailsSection({ title, status, children }) {
  return (
    <DetailsSectionContainer>
      <DetailsSectionHeaderContainer>
        <DetailsHeaderText>{title}</DetailsHeaderText>
        {status && (
          <StatusContainer>
            <Pip color={status.color} />
            <StatusText>{status.text}</StatusText>
          </StatusContainer>
        )}
      </DetailsSectionHeaderContainer>
      <DetailsSectionContent>{children}</DetailsSectionContent>
    </DetailsSectionContainer>
  );
}

DetailsSection.propTypes = {
  title: string.isRequired,
  status: shape({
    text: string,
    color: string,
  }),
  children: node.isRequired,
};
